<template>
  <section>
    <b-card>
      <div class="d-flex align-items-center" />
      <b-row class="match-height">
        <b-col cols="4">
          <b-card class="border-primary">
            <b-card-title>
              {{ $t('trip.shop.helicopter.additional_informations') }}
            </b-card-title>
          </b-card>
        </b-col>
        <!--CALENDAR-->
        <b-col cols="8">
          <b-card class="border-primary">
            <b-card-title>
              {{ $t('trip.shop.helicopter.availability') }}
            </b-card-title>
            <div class="app-calendar overflow-hidden border">
              <div class="row no-gutters">
                <!-- Calendar -->
                <div class="col position-relative">
                  <div class="card shadow-none border-0 mb-0 rounded-0">
                    <div class="card-body pb-0">
                      <b-form-datepicker
                        v-model="dateSelected"
                        :locale="$i18n.locale"
                        :no-flip="true"
                        :placeholder="$t('shop.transfer_availability.select_a_date')"
                        class="mb-1"
                      />
                      <full-calendar
                        ref="calendarSlot"
                        :options="calendarOptions"
                        :plugins="calendarOptions.plugins"
                        class="full-calendar"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import momentPlugin from '@fullcalendar/moment'

export default {
  name: 'TransferAvailability',

  components: {
    FullCalendar,
  },
  data() {
    return {
      dateSelected: '',
      currentDate: null,
      calendarOptions: {
        plugins: [momentPlugin, dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: 'timeGridWeek',
        allDaySlot: false,
        headerToolbar: {
          start: 'sidebarToggle, prev,next, title',
          end: 'timeGridWeek,listMonth',
        },
        events: [
          {
            groupId: 'slot1',
            title: '0',
            startTime: '08:00',
            endTime: '09:00',
            allDay: false,
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            color: '#ea5455',
            textColor: 'white',
            startRecur: '2021-07-04',
            endRecur: null,
            extendedProps: {
              slots: {
                percentage: 0,
              },
            },
          },
          {
            groupId: 'slot2',
            title: '25',
            startTime: '09:00',
            endTime: '10:00',
            allDay: false,
            daysOfWeek: [0, 1, 2, 3, 5, 6],
            color: '#ED8F03',
            textColor: 'white',
            startRecur: '2021-07-04',
            endRecur: null,
            extendedProps: {
              slots: {
                percentage: 25,
              },
            },
          },
          {
            title: '25',
            startTime: '10:00',
            endTime: '11:00',
            allDay: false,
            daysOfWeek: [0, 1, 2, 3, 5, 6],
            color: '#ED8F03',
            textColor: 'white',
            startRecur: '2021-07-04',
            endRecur: null,
            extendedProps: {
              slots: {
                percentage: 25,
              },
            },
          },
          {
            title: '25',
            startTime: '11:00',
            endTime: '12:00',
            allDay: false,
            daysOfWeek: [0, 1, 2, 3, 5, 6],
            color: '#ED8F03',
            textColor: 'white',
            startRecur: '2021-07-04',
            endRecur: null,
            extendedProps: {
              slots: {
                percentage: 25,
              },
            },
          },
          {
            title: '55',
            startTime: '12:00',
            endTime: '13:00',
            allDay: false,
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            color: '#28c76f',
            textColor: 'white',
            startRecur: '2021-07-04',
            endRecur: null,
            extendedProps: {
              slots: {
                percentage: 55,
              },
            },
          },
          {
            title: '55',
            startTime: '13:00',
            endTime: '14:00',
            allDay: false,
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            color: '#28c76f',
            textColor: 'white',
            startRecur: '2021-07-04',
            endRecur: null,
            extendedProps: {
              slots: {
                percentage: 55,
              },
            },
          },
          {
            title: '55',
            startTime: '14:00',
            endTime: '15:00',
            allDay: false,
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            color: '#28c76f',
            textColor: 'white',
            startRecur: '2021-07-04',
            endRecur: null,
            extendedProps: {
              slots: {
                percentage: 55,
              },
            },
          },
          {
            groupId: 'slot2',
            title: '25',
            startTime: '09:00',
            endTime: '10:00',
            allDay: false,
            daysOfWeek: [4],
            color: '#28c76f',
            textColor: 'white',
            startRecur: '2021-07-04',
            endRecur: null,
            extendedProps: {
              slots: {
                percentage: 0,
              },
            },
          },
          {
            title: '25',
            startTime: '10:00',
            endTime: '11:00',
            allDay: false,
            daysOfWeek: [4],
            color: '#28c76f',
            textColor: 'white',
            startRecur: '2021-07-04',
            endRecur: null,
            extendedProps: {
              slots: {
                percentage: 0,
              },
            },
          },
          {
            title: '25',
            startTime: '11:00',
            endTime: '12:00',
            allDay: false,
            daysOfWeek: [4],
            color: '#28c76f',
            textColor: 'white',
            startRecur: '2021-07-04',
            endRecur: null,
            extendedProps: {
              slots: {
                percentage: 0,
              },
            },
          },
        ],
        selectable: true,
        eventResizableFromStart: true,
        dragScroll: true,
        navLinks: true,
        eventTimeFormat: { // like '14:30:00'
          hour: '2-digit',
          minute: '2-digit',
          hourCycle: 'h23',
          meridiem: false,
        },
        slotLabelFormat: { // like '14:30:00'
          hour: '2-digit',
          minute: '2-digit',
          hourCycle: 'h23',
          meridiem: false,
        },
        dayHeaderFormat: 'dd, DD MMM',
        locale: this.$i18n.locale,
        firstDay: 1,
      },
    }
  },
  watch: {
    dateSelected(val) {
      const calendarApi = this.$refs.calendarSlot.getApi()
      calendarApi.gotoDate(val)
    },
  },
  methods: {},
}
</script>
<style lang="scss">
@import '@core/scss/vue/apps/calendar.scss';

.fc-daygrid-body.fc-daygrid-body-unbalanced {
  width: 100% !important;
}

.fc-scrollgrid-sync-table {
  width: 100% !important;
}

.fc-col-header {
  width: 100% !important;
}

.fc-selectDateBtn-button {
  margin-left: 2rem !important;
  border-color: #dcc181 !important;
  background-color: #dcc181 !important;
}
</style>
