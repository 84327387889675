var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.relatedProducts.length)?_c('b-card-body',[_c('div',{staticClass:"mt-4 mb-2 text-center"},[_c('h4',[_vm._v(_vm._s(_vm.$t('trip.shop.related_services')))])]),_c('b-overlay',{attrs:{"show":_vm.productsRelatedLoaded,"spinner-medium":""}},[_c('swiper',{staticClass:"swiper-responsive-breakpoints mb-2",attrs:{"options":_vm.swiperOptions}},[_vm._l((_vm.relatedProducts),function(product,index){return _c('swiper-slide',{key:index},[_c('b-card',{staticClass:"ecommerce-card",attrs:{"no-body":""}},[_c('div',{staticClass:"text-center pt-0"},[_c('b-img',{staticClass:"shop-img",attrs:{"alt":((product.product.name[_vm.userLang]) + "-" + (product.product.id)),"src":_vm.resolveProductImgScrc(product.product)},on:{"click":function($event){return _vm.goToShopServiceDetails(product)}}})],1),_c('b-card-body',[_c('h4',{staticClass:"item-name text-underline"},[_c('b-link',{attrs:{"to":{
                  name: 'shop-service-details',
                  params: {
                    product_id: product.product.id,
                    provider_id: _vm.$route.params.provider_id,
                    trip_id: _vm.$route.params.trip_id,
                    step_id: _vm.$route.params.step_id,
                    event_id: _vm.$route.params.event_id,
                    airport_id: _vm.$route.params.airport_id,
                  },
                }}},[_vm._v(" "+_vm._s(_vm._f("trans")(product.product.name))+" ")])],1),(product.product.mainCategory && product.product.mainCategory.name)?_c('p',[_vm._v(" "+_vm._s(_vm._f("trans")(product.product.mainCategory.name))+" ")]):_vm._e(),(product.product.minPrice && product.product.minPrice.displayPriceExcludingTaxes)?_c('b-card-text',{attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$tc('pricing.unit_price_before_tax', 1))+" : "+_vm._s(_vm._f("priceFormat")(product.product.minPrice.displayPriceExcludingTaxes))+" ")]):_c('b-card-text',[_vm._v(" "+_vm._s(_vm.$t('trip.shop.price_on_quote'))+" ")]),(product.product.mainVariant && product.product.mainVariant.packing)?_c('b-card-text',[(product.product.mainVariant.packing.pricingType)?_c('p',{staticClass:"py-0"},[_vm._v(" "+_vm._s(_vm.$t('service.prices.type'))+" : "+_vm._s(_vm._f("enumTranslate")(product.product.mainVariant.packing.pricingType,'pricing_type'))+" ")]):_vm._e(),(product.product.mainVariant.packing.name)?_c('p',{staticClass:"py-0"},[_vm._v(" "+_vm._s(_vm.$t('service.prices.type_measurement'))+" : "+_vm._s(_vm._f("trans")(product.product.mainVariant.packing.name))+" ")]):_vm._e()]):_vm._e(),(product.product.description)?_c('b-card-text',{staticClass:"item-description"},[_vm._v(" "+_vm._s(_vm._f("trans")(product.product.description))+" ")]):_vm._e()],1),_c('div',{staticClass:"item-options"},[(!product.selected)?_c('b-button',{staticClass:"btn-cart",on:{"click":function($event){return _vm.patchCart(product)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"ShoppingCartIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t('trip.shop.add_to_cart')))])],1):_c('b-button',{staticClass:"btn-cart ",attrs:{"to":{
                name: 'shop-checkout',
                params: {
                  provider_id: product.product.organization.id,
                  trip_id: _vm.$route.params.trip_id,
                  step_id: _vm.$route.params.step_id,
                  event_id: _vm.$route.params.event_id,
                  airport_id: _vm.$route.params.airport_id,
                },
              }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"ShoppingCartIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t('trip.shop.action.view_in_cart')))])],1)],1)],1)],1)}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"})],2)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }