<template>
  <b-card-body v-if="relatedProducts.length">
    <div class="mt-4 mb-2 text-center">
      <h4>{{ $t('trip.shop.related_services') }}</h4>
    </div>

    <!-- Swiper -->
    <b-overlay :show="productsRelatedLoaded" spinner-medium>
      <swiper class="swiper-responsive-breakpoints mb-2" :options="swiperOptions">
        <swiper-slide v-for="(product, index) in relatedProducts" :key="index">
          <b-card class="ecommerce-card" no-body>
            <div class="text-center pt-0">
              <b-img
                :alt="`${product.product.name[userLang]}-${product.product.id}`"
                :src="resolveProductImgScrc(product.product)"
                class="shop-img"
                @click="goToShopServiceDetails(product)"
              />
            </div>

            <!-- Product Details -->
            <b-card-body>
              <h4 class="item-name text-underline">
                <b-link
                  :to="{
                    name: 'shop-service-details',
                    params: {
                      product_id: product.product.id,
                      provider_id: $route.params.provider_id,
                      trip_id: $route.params.trip_id,
                      step_id: $route.params.step_id,
                      event_id: $route.params.event_id,
                      airport_id: $route.params.airport_id,
                    },
                  }"
                >
                  {{ product.product.name | trans }}
                </b-link>
              </h4>
              <p v-if="product.product.mainCategory && product.product.mainCategory.name">
                {{ product.product.mainCategory.name | trans }}
              </p>
              <b-card-text v-if="product.product.minPrice && product.product.minPrice.displayPriceExcludingTaxes" variant="primary">
                {{ $tc('pricing.unit_price_before_tax', 1) }} : {{ product.product.minPrice.displayPriceExcludingTaxes | priceFormat }}
              </b-card-text>
              <b-card-text v-else>
                {{ $t('trip.shop.price_on_quote') }}
              </b-card-text>
              <b-card-text v-if="product.product.mainVariant && product.product.mainVariant.packing">
                <p v-if="product.product.mainVariant.packing.pricingType" class="py-0">
                  {{ $t('service.prices.type') }} : {{ product.product.mainVariant.packing.pricingType | enumTranslate('pricing_type') }}
                </p>
                <p v-if="product.product.mainVariant.packing.name" class="py-0">
                  {{ $t('service.prices.type_measurement') }} : {{ product.product.mainVariant.packing.name | trans }}
                </p>
              </b-card-text>
              <b-card-text v-if="product.product.description" class="item-description">
                {{ product.product.description | trans }}
              </b-card-text>
            </b-card-body>

            <!-- Product Actions -->
            <div class="item-options">
              <b-button v-if="!product.selected" class="btn-cart" @click="patchCart(product)">
                <feather-icon class="mr-50" icon="ShoppingCartIcon" />
                <span>{{ $t('trip.shop.add_to_cart') }}</span>
              </b-button>

              <b-button
                v-else
                :to="{
                  name: 'shop-checkout',
                  params: {
                    provider_id: product.product.organization.id,
                    trip_id: $route.params.trip_id,
                    step_id: $route.params.step_id,
                    event_id: $route.params.event_id,
                    airport_id: $route.params.airport_id,
                  },
                }"
                class="btn-cart "
              >
                <feather-icon class="mr-50" icon="ShoppingCartIcon" />
                <span>{{ $t('trip.shop.action.view_in_cart') }}</span>
              </b-button>
            </div>
          </b-card>
          <!--          <b-link
            :to="{
              name: 'shop-service-details',
              params: {
                product_id: product.product.id,
                provider_id: $route.params.provider_id,
                trip_id: $route.params.trip_id,
                step_id: $route.params.step_id,
                event_id: $route.params.event_id,
                airport_id: $route.params.airport_id,
              },
            }"
          >
            <div class="item-heading">
              <h5 class="text-truncate mb-0">
                {{ product.product.name | trans }}
              </h5>
              <small v-if="product.product.organization" class="text-body"> {{ $t('common.by') }} {{ product.product.organization.name }}</small>
            </div>
            <div class="img-container w-50 mx-auto py-75">
              <b-img
                :src="
                  product.product.mainVariant && product.product.mainVariant.files && product.product.mainVariant.files.length
                    ? product.product.mainVariant.files[0].fileUrls[300]
                    : require('@/assets/images/logo/logo.png')
                "
                fluid
              />
            </div>
            <div class="item-meta">
              <p class="card-text text-primary mb-0">
                <span v-if="product.product.minPrice"> {{ product.product.minPrice.displayPriceIncludingTaxes | priceFormat }} {{ $t('trip.shop.cart.incl_taxes') }}</span>
                <span v-else>{{ $t('trip.shop.price_on_quote') }}</span>
              </p>
            </div>
          </b-link>-->
        </swiper-slide>

        <!-- Add Arrows -->
        <div slot="button-next" class="swiper-button-next" />
        <div slot="button-prev" class="swiper-button-prev" />
      </swiper>
    </b-overlay>
  </b-card-body>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { fetchOrganizationProductRelatedRequest } from '@/request/globalApi/requests/apiRequests'
import Cart from '@/models/Cart'

export default {
  name: 'ShopDetailsRelatedProduct',

  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      productsRelatedLoaded: false,
      relatedProducts: [],
      cart: new Cart(),
    }
  },
  computed: {
    userLang() {
      return this.$store.state.appConfig.lang
    },
  },
  watch: {
    $route() {
      this.APIFetchProductRelated()
    },
  },
  mounted() {
    this.APIFetchProductRelated()
  },
  methods: {
    APIFetchProductRelated() {
      this.productsRelatedLoaded = true
      const tripStepEventId = this.$route.params.event_id
      const organizationId = this.$route.params.provider_id
      const productId = this.$route.params.product_id
      fetchOrganizationProductRelatedRequest(tripStepEventId, organizationId, productId)
        .then(r => {
          this.relatedProducts = r.data.products
        })
        .finally(() => {
          this.productsRelatedLoaded = false
        })
    },
    patchCart(productEntity) {
      const tripStepId = this.$route.params.event_id
      if (productEntity.product.className === 'CoreProduct') {
        this.cart.patch(tripStepId, productEntity.product.mainVariant.id, 1).then(() => {
          // eslint-disable-next-line no-param-reassign
          productEntity.selected = true
        })
      }

      if (productEntity.product.className === 'PackageProduct') {
        this.cart.patchMandatoryPackage(tripStepId, productEntity.product.mainVariant.id, 1).then(() => {
          // eslint-disable-next-line no-param-reassign
          productEntity.selected = true
        })
      }
    },
    resolveProductImgScrc(product) {
      if (product?.mainVariant?.files?.length) return product.mainVariant.files[0]?.fileUrls[300]
      if (product?.mainCategory?.iconFileUrl) return product.mainCategory.iconFileUrl
      return require('@/assets/images/logo/logo.png')
    },
    goToShopServiceDetails(productEntity) {
      this.$router.push({
        name: 'shop-service-details',
        params: {
          product_id: productEntity.product.id,
          provider_id: this.$route.params.provider_id,
          trip_id: this.$route.params.trip_id,
          step_id: this.$route.params.step_id,
          event_id: this.$route.params.event_id,
          airport_id: this.$route.params.airport_id,
        },
      })
    },
  },
  setup() {
    const swiperOptions = {
      slidesPerView: 5,
      spaceBetween: 55,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1600: {
          slidesPerView: 4,
          spaceBetween: 55,
        },
        1300: {
          slidesPerView: 3,
          spaceBetween: 55,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 55,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 55,
        },
      },
    }

    return {
      swiperOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/swiper.scss';
@import '../../../../node_modules/swiper/css/swiper.css';

.ecommerce-application .swiper-responsive-breakpoints.swiper-container .swiper-slide {
  padding: 0 !important;
}

.card {
  margin-bottom: 0 !important;
}
.shop-img {
  width: 100%;
  height: 8rem;
  object-fit: contain;
}
</style>
